@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;1,300;1,500;1,600&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

body {
    background-color: #030312;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.close {
    scale: 0;
}

.rclose {
    right: -255px !important;

}

.disebled {
    pointer-events: none;
    color: #616161b9 !important;
}

#scrollHide {
    overflow: hidden;
}